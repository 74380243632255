<script>
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import InfoTooltip from "@/components/widgets/info-tooltip.vue";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {  
        InfoTooltip,
        SelectAutocompleteField      
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="minimum_amount">Gasto mínimo</label>
            <InfoTooltip class="ml-2" title="Este campo te permite establecer el gasto mínimo (subtotal) permitido para poder usar el cupón." />
            <input
                id="minimum_amount"
                v-model="value.minimum_amount"
                type="number"
                class="form-control"
                placeholder="sin mínimo"
                :class="{ 'is-invalid': v.coupon.minimum_amount.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <label for="maximum_amount">Gasto máximo</label>
            <InfoTooltip class="ml-2" title="Este campo te permite establecer el gasto máximo (subtotal) permitido al usar el cupón." />
            <input
                id="maximum_amount"
                v-model="value.maximum_amount"
                type="number"
                class="form-control"
                placeholder="No hay máximo"
                :class="{ 'is-invalid': v.coupon.maximum_amount.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <label for="individual_use">Uso individual</label>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.individual_use"
                    class="custom-control-input"
                    id="individual_use"
                    :class="{ 'is-invalid': v.coupon.individual_use.$error && submitted }"
                />
                <label class="custom-control-label" for="individual_use">Marca esta casilla si el cupón no se puede utilizar en combinación con otros cupones.</label>
            </div>
        </div>
        <div class="form-group col-12">
            <label for="exclude_sale_items">Excluir artículos rebajados</label>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.exclude_sale_items"
                    class="custom-control-input"
                    id="exclude_sale_items"
                    :class="{ 'is-invalid': v.coupon.exclude_sale_items.$error && submitted }"
                />
                <label class="custom-control-label" for="exclude_sale_items">Marca esta casilla si el cupón no debe aplicarse a artículos rebajados. Los cupones para artículos concretos sólo funcionarán si el artículo no está rebajado. Los cupones para carrito completo solo funcionarán si no hay artículos rebajados en el carrito.</label>
            </div>
        </div>
        <div class="form-group col-12">
            <hr class="mt-3 mb-3"/>
            <label>Productos</label>
            <SelectAutocompleteField
                :url="`/products`"
                :multiple="true"
                :initialOptions="value.products"
                :placeholder="'Busca un producto'"
                v-model="value.product_ids"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12">
            <label>Productos excluidos</label>
            <SelectAutocompleteField
                :url="`/products`"
                :multiple="true"
                :initialOptions="value.exclude_products"
                :placeholder="'Busca un producto'"
                v-model="value.exclude_product_ids"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12">
            <hr class="mt-3 mb-3"/>
            <label>Categorías de productos</label>
            <SelectAutocompleteField
                :url="`/product-categories`"
                :multiple="true"
                :initialOptions="value.product_categories"
                :placeholder="'Cualquier categoría'"
                v-model="value.product_category_ids"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12">
            <label>Categorías excluidas</label>
            <SelectAutocompleteField
                :url="`/product-categories`"
                :multiple="true"
                :initialOptions="value.exclude_product_categories"
                :placeholder="'Busca un producto'"
                v-model="value.exclude_product_category_ids"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12">
            <label for="email_restrictions">Correos electrónicos permitidos</label>
            <InfoTooltip class="ml-2" title="Lista de correos electrónicos permitidos contra los que probar el correo electrónico de facturación del cliente al hacer un pedido. Separa las direcciones de correo electrónico con comas. También puedes usar un asterisco (*) para hacer coincidir partes de un correo electrónico. Por ejemplo «*@gmail.com» coincidirá con todas las direcciones de correo electrónico de gmail." />
            <input
                id="email_restrictions"
                v-model="value.email_restrictions"
                type="text"
                class="form-control"
                placeholder="Sin restricciones"
                :class="{ 'is-invalid': v.coupon.email_restrictions.$error && submitted }"
            />
        </div>
    </div>
</template>